import { PortableText } from "@portabletext/react";
import { getDayOfYear, getYear } from "date-fns";
import { useMemo } from "react";
import Image from "next/image";
import { FDR_FALLBACK_IMAGE } from "@/constants/media";
import Link from "@/components/link/Link";
import Tag from "@/components/tag/Tag";
import ArticleCard from "@/components/articleCard";
import useAuthors from "@/utils/hooks/useAuthors";
import styles from "./AuthorSpotlight.module.scss";

function getDaysInYear(year: number) {
  // Check if the year is a leap year
  if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
    return 366;
  } else {
    return 365;
  }
}

const REFERENCE_YEAR = 2020;
const CONSECUTIVE_DAYS_TO_SHOW_AUTHOR = 3;

function computeYearBasedIndex(
  year: number,
  dayOfYear: number,
  numIds: number,
  period: number = CONSECUTIVE_DAYS_TO_SHOW_AUTHOR
) {
  // Calculate the total number of days since some arbitrary reference year
  let cumulativeDays = 0;
  for (let y = REFERENCE_YEAR; y < year; y++) {
    cumulativeDays += getDaysInYear(y);
  }

  // Add the current day of the current year for the full count
  cumulativeDays += dayOfYear;

  // Compute a deterministic index: (total days / period) % num_ids
  return Math.floor(cumulativeDays / period) % numIds;
}

type Author = ReturnType<typeof useAuthors>["data"][number];
const byId = (left: Author, right: Author) => {
  if (left.id === right.id) return 0;
  return left.id < right.id ? -1 : 1;
};

const useSpotlightAuthor = () => {
  const { data: authors, ...rest } = useAuthors();

  const sortedAuthors = authors
    .filter((author) => author.hideContent !== true) // Only show active/non-deprioritized authors
    .sort(byId); // Sorting required for determinism

  const featuredAuthorIndex = useMemo(() => {
    const today = new Date();
    return computeYearBasedIndex(
      getYear(today),
      getDayOfYear(today),
      sortedAuthors.length
    );
  }, [sortedAuthors.length]);

  return { featuredAuthor: sortedAuthors[featuredAuthorIndex], ...rest };
};

const AuthorSpotlight = () => {
  const { featuredAuthor } = useSpotlightAuthor();

  if (!featuredAuthor) {
    return null;
  }

  const { name, slug, profileImageUrl, bio, authoredContent, favoriteSports } =
    featuredAuthor;

  const articles = authoredContent ?? [];
  const [featuredArticle] = articles;

  /**
   * This typechecking hack informs the TypeScript compiler that sports are a list of non-null values.
   * Apparently, TypeScript v5.5+ resolves this issue.
   */
  type Sport = NonNullable<NonNullable<typeof favoriteSports>[number]>;
  const sports =
    favoriteSports?.filter((sport): sport is Sport => sport != null) ?? [];

  const showFallbackImg = !profileImageUrl;
  const imgSizePx = showFallbackImg ? 78 : 88;

  const authorName = slug ? (
    <Link next href={`author/${slug}`}>
      {name}
    </Link>
  ) : (
    name
  );

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Author Spotlight</h1>

      <div className={styles.imageArea}>
        <div className={styles.authorBackground}>
          <Image
            src="/research/images/author-spotlight-bg.png"
            alt="Background pattern"
            height={449}
            width={600}
          />
        </div>
        <div className={styles.authorProfileImg}>
          <Image
            src={profileImageUrl || FDR_FALLBACK_IMAGE}
            alt={`${name}'s Profile Photo`}
            width={imgSizePx}
            height={imgSizePx}
          />
        </div>
      </div>

      <div className={styles.nameArea}>
        <h2 className={styles.name}>{authorName}</h2>
        {sports.length > 0 && (
          <>
            <span className={styles.divider}>&nbsp;|&nbsp;</span>
            <div className={styles.sportsList}>
              {sports.map((sport) => (
                <Tag key={sport.name} label={sport.name} />
              ))}
            </div>
          </>
        )}
      </div>

      {bio && (
        <div className={styles.bio}>
          <PortableText value={JSON.parse(bio)} />
        </div>
      )}

      {featuredArticle && (
        <>
          <div className={styles.spacer} />

          <h3 className={styles.prompt}>Read their most recent article</h3>
          <div>
            <ArticleCard
              author={featuredAuthor}
              image={featuredArticle.thumbnailImageUrl?.url}
              link={`/${featuredArticle.slug}`}
              origin="author_list"
              publishedOn={featuredArticle.modifiedDate}
              sportName={featuredArticle.sport.name}
              sportSlug={featuredArticle.sport.slug}
              title={featuredArticle.title}
              displayHorizontal
              hasPadding={false}
              showAuthor={false}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default AuthorSpotlight;
